import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import styles from "../style/templates/book.module.scss";

export default function Book({ data }) {
  const post = data.markdownRemark;
  return (
    <Layout>
      <div className={styles.bookPost}>
        <Link className={styles.back} to="/books">
          {"< BACK"}
        </Link>
        <img src={post.frontmatter.cover} />
        <div className={styles.actions}>
          <a href={`${post.frontmatter.purchase_url}`} target="_blank">
            <button>BUY ON AMAZON</button>
          </a>
        </div>
        <article className="post-content">
          <label>{`Published: ${post.frontmatter.date}`}</label>
          {post.html && <div dangerouslySetInnerHTML={{ __html: post.html }} />}
        </article>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        cover
        purchase_url
      }
    }
  }
`;
