import React from "react";
import classNames from "classnames";
import { Link } from "gatsby";
import styles from "../style/layouts/index.module.scss";
import "../style/normalize.css";
import "../style/all.scss";

const Layout = (props) => {
  const { title, children } = props;
  const [toggleNav, setToggleNav] = React.useState(false);
  const pathname =
    typeof window === `undefined` ? null : window.location.pathname;

  const letfNav = [
    { label: "About", link: "about" },
    { label: "Books", link: "books" },
    { label: "Blog", link: "blog" },
    { label: "Articles", link: "articles" },
    { label: "Videos", link: "videos" },
  ];

  const rightNav = [
    { label: "Facebook", link: "https://www.facebook.com/jscottgeorge1" },
    { label: "Twitter", link: "https://twitter.com/revjscottgeorge" },
    {
      label: "LinkedIn",
      link: "https://www.linkedin.com/in/j-scott-george-67774a67/",
    },
  ];

  return (
    <div className={`site-wrapper ${toggleNav ? `site-head-open` : ``}`}>
      <header className="site-head">
        <div id="menu" className="site-head-container">
          <a className="nav-burger" onClick={() => setToggleNav(!toggleNav)}>
            <div
              className="hamburger hamburger--collapse"
              aria-label="Menu"
              role="button"
              aria-controls="navigation"
            >
              <div className="hamburger-box">
                <div className="hamburger-inner" />
              </div>{" "}
              <div className="hamburger-text-menu-text hidden">Menu</div>
            </div>
          </a>
          <nav id="swup" className="site-head-left">
            <ul className="nav" role="menu">
              {letfNav.map((item, index) => {
                return (
                  <li
                    className={classNames(
                      styles.navItem,
                      pathname && pathname.match(item.link)
                        ? styles.active
                        : null
                    )}
                    role="menuitem"
                    key={index}
                  >
                    <Link to={`/${item.link}`}>{item.label}</Link>
                  </li>
                );
              })}
            </ul>
          </nav>
          <div className="site-head-center">
            <Link className="site-head-logo" to={`/`}>
              {"J. Scott George"}
            </Link>
          </div>
          <div className="site-head-right">
            <div className="social-links">
              {rightNav.map((item, index) => {
                return (
                  <a
                    href={item.link}
                    title="Facebook"
                    target="_blank"
                    rel="noopener noreferrer"
                    key={index}
                  >
                    {item.label}
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </header>
      <main id="site-main" className={styles.siteMain}>
        <div id="swup" className="transition-fade">
          {children}
        </div>
      </main>
      <footer className="site-foot">
        &copy; {new Date().getFullYear()} <Link to={`/`}>{title}</Link>
      </footer>
    </div>
  );
};

export default Layout;
